<template>
  <div class="flex flex-col w-full mx-auto p-8">
  

    <div class="flex justify-between my-4 h-96">
      <!-- Sidebar -->
       <!-- Select Location-->
      <div class="mb-4 bt-2">
     </div>
    <div class="w-64 text-white p-5 bg-[#1E145E] absolute z-50 right-4 top-4">
      <h2 class="text-xl mb-2 mt-4">Location</h2>
      <div class="mb-4 bg-orange">
        <label for="locationSelect">Select Location</label>
        <select v-model="selectedLocation" @change="handleLocationChange" id="locationSelect" class="w-full p-2 border rounded text-black">
          <option value="nothing-selected" disabled>Nothing selected</option>
          <option v-for="location in locations" :key="location.id" :value="location">
            {{ location.name }} ({{ location.region }})
          </option>
        </select>
      </div>



<!--        <h2 class="text-xl mb-2 pt-4">Device</h2>-->
<!--        <div class="mb-4 bg-orange">-->
<!--          <label class="block text-sm font-bold mb-2">Select Device Type</label>-->
<!--          <select v-model="selectedDeviceType" @change="fetchDevices" class="w-full p-2 border rounded text-black">-->
<!--            <option value="nothing-selected" disabled>Nothing selected</option>-->
<!--            <option v-for="type in deviceTypes" :key="type.id" :value="type.id">{{ type.name }}</option>-->
<!--          </select>-->
<!--        </div>-->

        <div v-if="devices.length > 0" class="mb-4">
          <label for="deviceBox" class="block text-sm font-bold mb-2">Select Device</label>
          <div class="space-y-2">
            <div v-for="device in devices" :key="device.id" class="flex items-center">
              <input :id="'deviceBox-' + device.id" name="deviceBox" type="radio" :value="device.id" v-model="selectedDevice" @change="handleDeviceChange" class="mr-2">
              <label :for="'deviceBox-' + device.id" class="whitespace-nowrap">{{ device.name }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Main Content -->
      <div v-show="!showBiView" class="w-4/5">
        <div class="flex justify-between items-center mb-4">
        <button v-if="selectedDevice" class="bg-blue-500 text-white font-bold rounded-md px-3 py-1" @click="changeView">
                  {{ showBiView ? 'BI View' : 'Map View' }}
        </button>

          <select id="chartView" v-model="selectedChartType" @change="handleDeviceChange" class="p-1 border rounded text-black text-sm ml-4">
            <option value="all-logs">All Logs</option>
            <option value="daily-average">Daily average</option>
            <option value="weekly-average">Weekly Average</option>
            <option value="monthly-average">Monthly Average</option>
          </select>
        </div>

        <div class="mb-4">
          <line-chart :chart-data="chartData" />
        </div>
        <div class="mb-4">
          <button @click="exportData" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Export Data</button>
        </div>
        <div class="overflow-x-auto" v-if="deviceData.length">
          <table class="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th v-for="(value, key) in deviceData[0]" :key="key" class="px-4 py-2 border border-gray-300 bg-gray-100">{{ key }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in deviceData" :key="index" class="hover:bg-gray-100">
                <td v-for="(value, key) in data" :key="key" class="border px-4 py-2">{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-element
          v-if="totalItems > 0"
          :total-items="totalItems"
          :page-size="perPage"
          v-model:current-page="currentPage"
          @page-change="fetchPaginatedDeviceData"
        />
      </div>
      <!-- BI View -->
      <div v-show="showBiView" class="w-full relative">

        <button v-if="selectedDevice" class="absolute left-0 top-0 z-20 bg-blue-500 text-white font-bold rounded-md px-3 py-1 mb-2" @click="changeView">
            {{ showBiView ? 'BI View' : 'Map View' }}
        </button>

        <MapBox :location-data="selectedLocation" />

      </div>
    </div>

    <div class="flex flex-row justify-between">
      <div class="flex flex-col">
        <h1 class="text-4xl font-thin text-start my-6">Analytics</h1>
        <div class="flex flex-row space-x-2">
          <h1 @click="selectTimeframe('last7days')" :class="['font-bold text-2xl cursor-pointer rounded-lg hover:bg-slate-100 p-2', selectedTimeframe === 'last7days' ? 'text-slate-800' : 'text-slate-400']">Last 7 Days</h1>
          <h1 @click="selectTimeframe('last30days')" :class="['font-bold text-2xl cursor-pointer rounded-lg hover:bg-slate-100 p-2', selectedTimeframe === 'last30days' ? 'text-slate-800' : 'text-slate-400']">Last 30 Days</h1>
          <h1 @click="selectTimeframe('custom')" :class="['font-bold text-2xl cursor-pointer rounded-lg hover:bg-slate-100 p-2', selectedTimeframe === 'custom' ? 'text-slate-800' : 'text-slate-400']">Custom</h1>
        </div>
        <!-- Custom date picker shown only when 'Custom' is selected -->
        <div v-if="selectedTimeframe === 'custom'" class="flex flex-row space-x-4 mt-4">
          <input type="date" v-model="customStartDate" class="border p-2 rounded" />
          <input type="date" v-model="customEndDate" class="border p-2 rounded" />
          <button @click="applyCustomRange" class="bg-blue-500 text-white px-4 py-2 rounded">Apply</button>
        </div>
      </div>
      <div class="flex flex-row space-x-4 items-center">
        <div class="w-40 h-20 bg-red-600 rounded-2xl cursor-pointer" @click="filterData('3_failures')">
          <h1 class="text-xl font-bold text-white">3+ failures</h1>
          <h1 class="text-4xl font-bold text-white"> {{ threeFailures }} </h1>
        </div>
        <div class="w-40 h-20 bg-orange-500 rounded-2xl cursor-pointer" @click="filterData('2_failures')">
          <h1 class="text-xl font-bold text-white">2 failures</h1>
          <h1 class="text-4xl font-bold text-white">{{ twoFailures }}</h1>

        </div>
        <div class="w-40 h-20 bg-green-500 rounded-2xl cursor-pointer" @click="filterData('1_failure')">
          <h1 class="text-xl font-bold text-white">1 failure</h1>
          <h1 class="text-4xl font-bold text-white">{{ oneFailure }}</h1>

        </div>
      </div>
    </div>
    <DrillDownTable
      :fetch-string="fetchString"
      :details-fetch-string="detailsFetchString"
    />


  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import LineChart from '../components/LineChart.vue';
import PaginationElement from '../components/PaginationElement.vue';
import MapBox from "@/components/MapBox.vue";
import DrillDownTable from "@/components/DrillDownTable.vue";

const deviceData = ref([]);
const chartData = ref({});
const currentPage = ref(1);
const totalItems = ref(0);
const perPage = ref(10);
// const selectedDeviceType = ref('nothing-selected');
const selectedDevice = ref(null);
const selectedChartType = ref('daily-average'); // Set the default value to 'daily-average'
// const deviceTypes = ref([]);
const devices = ref([]);
const selectedLocation = ref('nothing-selected');
const locations = ref([]);
const selectedTimeframe = ref(null);
const customStartDate = ref('');
const customEndDate = ref('');
const logSummary = ref({});
const threeFailures = ref(0);
const twoFailures = ref(0);
const oneFailure = ref(0);
const threeFailuresRoutes = ref([]);
const twoFailuresRoutes = ref([]);
const oneFailureRoutes = ref([]);
const fetchString = ref('');
const detailsFetchString = ref('logs/get_route_error_log_details/{id}/?');

const startDate = ref('');
const endDate = ref('');


const API_URL = process.env.VUE_APP_API_URL;

// Define labelable columns and their colors
const labelColumns = {
  temperature: { label: 'Temperature', color: 'rgba(255, 99, 132, 1)', backgroundColor: 'rgba(255, 99, 132, 0.2)' },
  humidity: { label: 'Humidity', color: 'rgba(54, 162, 235, 1)', backgroundColor: 'rgba(54, 162, 235, 0.2)' },
};

// Define the chart view types
const chartViewTypes = {
  'all-logs': { label: 'Time', field: 'time' },
  'daily-average': { label: 'Date', field: 'date' },
  'weekly-average': { label: 'Week', field: 'week' },
  'monthly-average': { label: 'Month', field: 'month' },
};

const showBiView = ref(true);

const changeView = () => {
  showBiView.value = !showBiView.value;
};

watch(selectedDevice, (newValue) => {
  if (newValue.length > 0) {
    showBiView.value = true;
  }
  else {
    showBiView.value = false;
  }
});

const fetchChartData = async () => {
  if (!selectedDevice.value) {
    chartData.value = {};
    return;
  }

  try {
    console.log('Fetching chart data...');
    const apiUrl = `${API_URL}iotdata/dragino_data_all/?device=${selectedDevice.value}&type_of_chart=${selectedChartType.value}`;
    console.log('API URL:', apiUrl);
    const response = await axios.get(apiUrl);
    console.log('Response Data:', response.data);

    if (response.headers['content-type'] === 'application/json') {
      const all_data = response.data;

      // Get the appropriate field for the selected chart type
      const viewType = chartViewTypes[selectedChartType.value];
      const labels = all_data.map(item => item[viewType.field]);

      const datasets = Object.keys(labelColumns).map(key => ({
        label: labelColumns[key].label,
        data: all_data.map(item => item[key]),
        borderColor: labelColumns[key].color,
        backgroundColor: labelColumns[key].backgroundColor
      }));

      chartData.value = {
        labels: labels,
        datasets: datasets
      };
    } else {
      console.error('Unexpected response format:', response.data);
      return;
    }
  } catch (error) {
    console.error('Error fetching chart data:', error.response.data);
  }
};

const fetchPaginatedDeviceData = async (page = currentPage.value) => {
  if (!selectedDevice.value) {
    deviceData.value = [];
    return;
  }

  try {
    console.log('Fetching paginated device data...');
    const apiUrl = `${API_URL}iotdata/dragino_data_paginated/?page=${currentPage.value}&device=${selectedDevice.value}&type_of_chart=${selectedChartType.value}`;
    console.log('API URL:', apiUrl);
    const response = await axios.get(apiUrl);
    console.log('Response Data:', response.data);
    console.log('Response Headers:', response.headers);

    if (response.headers['content-type'] === 'application/json') {
      deviceData.value = Array.isArray(response.data.results) ? response.data.results : [];
      totalItems.value = response.data.count;
      currentPage.value = page;

    } else {
      console.error('Unexpected response format:', response.data);
      return;
    }
  } catch (error) {
    console.error('Error fetching paginated device data:', error.response.data);
  }
};

const fetchLogSummary = async (startDate, endDate) => {
  try {
    const response = await axios.get(`${API_URL}logs/get_route_error_summary/?start_date=${startDate}&end_date=${endDate}&location=${selectedLocation.value.id || ''}`);
    console.log('Log Summary:', response.data);
    logSummary.value = response.data;
    threeFailures.value = response.data.grouped_results['3_or_more_scenarios'].count;
    twoFailures.value = response.data.grouped_results['2_scenarios'].count;
    oneFailure.value = response.data.grouped_results['1_scenario'].count;

    threeFailuresRoutes.value = response.data.grouped_results['3_or_more_scenarios'].details;
    twoFailuresRoutes.value = response.data.grouped_results['2_scenarios'].details;
    oneFailureRoutes.value = response.data.grouped_results['1_scenario'].details;

  } catch (error) {
    console.error('Error fetching log summary:', error);
  }

};

const handleDeviceChange = () => {
  currentPage.value = 1;
  fetchChartData();
  fetchPaginatedDeviceData();
};

const fetchLocations = async () => {
  try {
    const response = await axios.get(`${API_URL}masterdata/get_locations/`);
    console.log('Depots:', response.data);
    locations.value = response.data;
  } catch (error) {
    console.error('Error fetching locations:', error);
    locations.value = []; // Reset on error
  }
};


const exportData = () => {
  if (deviceData.value.length === 0) {
    console.warn('No data to export');
    return;
  }

  // Get the keys (column names) dynamically from the first item
  const keys = Object.keys(deviceData.value[0]);
  const csvContent = 'data:text/csv;charset=utf-8,'
    + keys.join(',') + '\n'
    + deviceData.value.map(e => keys.map(k => e[k]).join(',')).join('\n');

  const link = document.createElement('a');
  link.setAttribute('href', encodeURI(csvContent));
  link.setAttribute('download', 'device_data.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleLocationChange = () => {
  currentPage.value = 1;
  fetchLogSummary(startDate.value, endDate.value);
  selectTimeframe(selectedTimeframe.value);
};


const selectTimeframe = (timeframe) => {
  selectedTimeframe.value = timeframe;
  // Set endDate to the current date
  endDate.value = new Date().toISOString().split('T')[0];

  if (timeframe === 'last7days') {
    const last7Days = new Date();
    last7Days.setDate(last7Days.getDate() - 7);
    startDate.value = last7Days.toISOString().split('T')[0];
  } else if (timeframe === 'last30days') {
    const last30Days = new Date();
    last30Days.setDate(last30Days.getDate() - 30);
    startDate.value = last30Days.toISOString().split('T')[0];
  } else if (timeframe === 'custom') {
    // Do not set fetchString yet, wait for the user to apply the custom range
      fetchString.value = `logs/get_route_error_logs/?start_date=${startDate.value}&end_date=${endDate.value}&location=${selectedLocation.value.id || ''}`;
      fetchLogSummary(startDate.value, endDate.value);
  }

  // Set fetchString for all non-custom options
  fetchString.value = `logs/get_route_error_logs/?start_date=${startDate.value}&end_date=${endDate.value}&location=${selectedLocation.value.id || ''}`;
  fetchLogSummary(startDate.value, endDate.value);
};

const applyCustomRange = () => {
  if (customStartDate.value && customEndDate.value) {
    startDate.value = customStartDate.value;
    endDate.value = customEndDate.value;
    selectTimeframe('custom');

  } else {
    alert('Please select both start and end dates.');
  }


};

const filterData = (filter) => {
  let data = [];

  if (filter === '3_failures') {
    data = threeFailuresRoutes.value.map(route => route.route);
  } else if (filter === '2_failures') {
    data = twoFailuresRoutes.value.map(route => route.route);
  } else if (filter === '1_failure') {
    data = oneFailureRoutes.value.map(route => route.route);
  }

  const filterQuery = data.join(',');

  fetchString.value = `logs/get_route_error_logs/?start_date=${startDate.value}&end_date=${endDate.value}&location=${selectedLocation.value?.id || ''}&filter=${filterQuery}`;

};


onMounted(() => {
  fetchLocations();
  selectTimeframe('last30days');

});




watch(currentPage, fetchPaginatedDeviceData);
</script>

<style scoped>
</style>
