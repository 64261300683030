<template>
  <div id="app" class="sm:flex-row flex-col flex">
    <!-- Conditionally render the SideBar based on the current route -->
    <SideBar v-if="showSidebar" />
    <router-view />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router'; // Import useRoute to access the current route
import SideBar from './components/SideBar.vue'; // Import the SideBar component

// Get the current route
const route = useRoute();

// Define the routes where the sidebar should be hidden
const hideOnPages = ['/login', '/forgot-password', '/logout'];

// Use a computed property to track route changes reactively and determine if the sidebar should be shown
const showSidebar = computed(() => {
  return !hideOnPages.includes(route.path); // Return true if the route is not in hideOnPages
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1E145E;
}

:root {
  --primary: #d95661;
  --grey: #052249;
  --dark: #201162;
  --dark-alt: #b3b4c2e3;
  --light: #ffffff;
  --sidebar-width: 400px;
}

* {
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background: var(--light);
}

button {
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  background: none;
}

.app {
  display: flex;

  main {
    flex: 1 1 0;
    padding: 2rem;

    @media (max-width: 1024px) {
      padding-left: 6rem;
    }
  }
}
</style>
